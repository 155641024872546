*,
a:focus
{
	outline: none;
}

body
{
	-webkit-font-smoothing: antialiased;
}

header#top
{
	min-height: 620px;
	padding-bottom: 50%;
	position: relative;
	background: url('/images/sujet.jpg') no-repeat center center;
	background-size: cover;

	.header-text
	{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 80px;
		text-align: center;
	}

	h1,
	h2
	{
		color: #fff;
	}

	h1
	{
		font-size: 50px;
		margin: 0;

		@media screen and (max-width: $screen-xs-max) {
			font-size: 36px;
		}
	}

	h2
	{
		font-size: 25px;
		margin: 10px 0 55px;

		@media screen and (max-width: $screen-xs-max) {
			font-size: 20px;
		}
	}

	.btn
	{
		margin: 0 18px;
		min-width: 200px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.button-separator
	{
		@extend .hidden-md;
		@extend .hidden-lg;
	}

	@media screen and (min-width: 1920px) {
		padding-bottom: 0;
		height: 960px;
	}

	@media screen and (max-width: $screen-xs-max) {
		background-image: url('/images/mobile_sujet.jpg');

		.btn
		{
			min-width: 200px;
		}
	}
}

.nobr
{
	white-space: nowrap;
}

.wow
{
	visibility: hidden;
}

#logo
{
	width: 120px;
	height: 118px;
	position: absolute;
	left: 0;
	right: 0;
	top: 30px;
	margin: 0 auto;
	background: url('/images/icons/logo.svg') no-repeat center;
	background-size: contain;

	@media screen and (max-width: $screen-xs-max) {
		top: 11px;
	}
}

#login-button,
#menu-button
{
	position: absolute;
	top: 30px;
	width: 65px;
	padding-top: 65px;
	background-size: contain;
	text-align: center;
	color: #fff;
	@extend .font-hiroshige;
	background: no-repeat center top;
	font-size: 13px;
	cursor: pointer;
	text-decoration: none;

	@media screen and (max-width: $screen-xs-max) {
		top: 11px;
	}
}

#login-button
{
	left: 50px;
	background-image: url('/images/icons/login.svg');

	@media screen and (max-width: $screen-xs-max) {
		left: 14px;
	}
}

#menu-button
{
	right: 50px;
	background-image: url('/images/icons/menu.svg');

	@media screen and (max-width: $screen-xs-max) {
		right: 14px;
	}
}

.yellow
{
	color: $brand-primary;
}

p + .btn
{
	margin-top: 20px;
}

#background-container
{
	background: url('/images/content-logo.jpg') no-repeat center top, url('/images/content.jpg') repeat center top;
	background-size: 100% auto;
	padding-bottom: 25px;
}

.blur-container
{
	filter: blur(0px);
	transition: 0.5s all linear;
}

body.modal-open
{
	.blur-container
	{
		filter: blur(10px);
	}
}

#form-modal
{
	.modal-content
	{
		padding: 20px;
		background-color: $brand-primary;
	}

	.nav-justified
	{
		>li
		{
			border-bottom: 1px solid $brand-secondary;

			>a
			{
				border: none;
				color: $brand-secondary;
				background-color: transparent;
				@extend .font-hiroshige;
			}

			&.active
			{
				>a
				{
					border-bottom: 2px solid $gray-base;
				}
			}

			@media screen and (max-width: $screen-xs-max) {
				display: table-cell;
				width: 1%;

				a
				{
					margin-bottom: 0;
				}
			}
		}
	}

	img.separator
	{
		width: 100%;
		margin: 35px 0;
	}

	.btn.btn-primary
	{
		border-color: $brand-secondary;
		color: $brand-secondary;
	}

	.btn.btn-link
	{
		color: $brand-secondary;
		margin-top: 10px;
	}

	.form-control
	{
		background-color: $brand-secondary;
		border-color: $brand-secondary;

		&.not-empty
		{
			background-color: $brand-primary;
			border-color: $brand-primary;
			border-bottom-color: $brand-secondary;
			box-shadow: none;
			color: $brand-secondary;
		}
	}

	input[type=checkbox]
	{
		+ label
		{
			background-image: url('/images/form/checkbox_dark.svg');
		}

		&:checked + label
		{
			background-image: url('/images/form/checkbox_dark_checked.svg');
		}
	}
}

.modal
{
	text-align: center;
	padding: 0!important;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -4px;
	}

	.close-button
	{
		display: block;
		position: absolute;
		right: 75px;
		top: 45px;
		background: url('/images/icons/close.svg') no-repeat center top;
		background-size: 25px;
		padding-top: 30px;
		color: #fff;
		@extend .font-hiroshige;
		text-align: center;
		cursor: pointer;
	}

	@media screen and (max-width: $screen-xs-max) {
		.close-button
		{
			top: 20px;
			right: 20px;
		}

		&:before
		{
			height: 80px;
		}
	}
}

.modal-dialog
{
	// margin-top: 120px;
	display: inline-block;
	text-align: left;
	vertical-align: middle;
}

#main-navi
{
	position:relative;
	margin-bottom: 55px;
	overflow: hidden;
	box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 1);
	background-color: rgba(0,0,0,0.2);
	text-align: center;

	ul.nav
	{
		padding: 0 40px 2px;
		position: relative;

		&,
		& > li
		{
			display: inline-block;
		}

		li
		{
			z-index: 1;

			a
			{
				background: transparent;
				font-size: 20px;
				@extend .font-hiroshige;
				color: #fff;
				padding: 30px 25px 0px;
				position: relative;
				bottom: -2px;

				span
				{
					display: inline-block;
					border-bottom: 2px solid transparent;
					transition: 0.3s all;
				}
			}

			&.secured
			{
				a
				{
					cursor:pointer;
					color: rgba(255,255,255,0.25);
				}
			}

			&.active a,
			& a:hover
			{
				span
				{
					border-color: $brand-primary;
				}
			}
		}

		&:before
		{
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
			border-bottom-left-radius: 30%;
			border-bottom-right-radius: 30%;
			left: 0;
			right: 0;
			height: 40px;
			background: radial-gradient(ellipse at top, rgba(0,0,0,0.4) 0, rgba(0,0,0,0) 70%);
		}

		&:after
		{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			content: "";
			display: block;
			height: 2px;
			background: linear-gradient(to right, rgba(102,102,102,0.1) 0%,rgba(102,102,102,0.8) 25%,rgba(102,102,102,0.8) 75%,rgba(102,102,102,0.1) 100%);
		}
	}

	.tab-content
	{
		margin-top: 60px;
		text-align:left;
		height: 0;
		overflow: hidden;
		transition: 0.5s height linear;

		&.open
		{
			height: 210px;
		}
	}

	.tab-pane
	{
		height: 250px;
		overflow: auto;
		-webkit-overflow-scrolling: touch;

		> div
		{
			margin: auto;
			box-sizing: content-box;
			padding: 0 20px;
			min-height: 1px;

			&.empty
			{
				color: #fff;
				text-align: center;
				@extend .font-hiroshige;

				.no-events
				{
					span
					{
						color: $brand-primary;
						cursor: pointer;
					}
				}
			}
		}

		a
		{
			display: inline-block;
			width: 250px;
			min-height: 160px;
			padding: 3px 20px;
			border: 2px solid transparent;
			float: left;
			text-decoration: none;
			line-height: normal;
			transition: 0.5s all;
			position: relative;
			margin-left: 1px;

			&.active,
			&:hover
			{
				border-color: $brand-primary;
			}

			time
			{
				display: block;
				@extend .font-bold;
				color: $brand-primary;
				font-size: 12px;
				margin: 15px 0 5px;
			}

			span.text
			{
				color: #fff;
				font-size: 18px;
			}

			span.image
			{
				background: no-repeat center;
				background-size: cover;
				height: 70px;
				display: block;
			}

			&:before
			{
				content: "";
				display: block;
				position: absolute;
				left: -3px;
				top: 0;
				bottom: 0;
				border-left: 1px solid $gray;
			}

			&:first-child:before
			{
				display: none;
			}
		}
	}

	@media screen and (max-width: $screen-sm-max){
		padding-bottom: 75px;

		.tab-content,
		#swipe-hint
		{
			display: none;
		}

		ul.nav
		{
			display: block;
			padding: 0;

			li
			{
				position: relative;
				display: block;
				background: url('/images/navi/touch-right.svg') no-repeat 15px 39px;
				background-size: 7px auto;

				a
				{
					span
					{
						border-bottom: none;
					}
				}

				&:before
				{
					content: "";
					display: block;
					position: absolute;
					left: 0;
					right: 0;
					top: 100%;
					border-bottom-left-radius: 30%;
					border-bottom-right-radius: 30%;
					left: 0;
					right: 0;
					height: 40px;
					background: radial-gradient(ellipse at top, rgba(0,0,0,0.4) 0, rgba(0,0,0,0) 70%);
					z-index: -1;
					margin-top: 15px;
				}

				&:after
				{
					position: absolute;
					left: 0;
					right: 0;
					bottom: -15px;
					content: "";
					display: block;
					height: 2px;
					background: linear-gradient(to right, rgba(102,102,102,0.1) 0%,rgba(102,102,102,0.8) 25%,rgba(102,102,102,0.8) 75%,rgba(102,102,102,0.1) 100%);
				}
			}

			&:before,
			&:after
			{
				display: none;
			}
		}

		&.item-selected
		{
			padding-bottom: 0;

			ul.nav
			{
				li
				{
					background: url(/images/navi/touch-down.svg) no-repeat 15px 39px;
					background-size: auto 7px;

					&:not(.active) {
						display: none;
					}
				}
			}

			.tab-content,
			#swipe-hint
			{
				display: block;
			}

			#select-hint
			{
				display: none;
			}
		}
	}
}

html
{
	#main-navi
	{

		.scroll-button
		{
			position: absolute;
			top: 0;
			bottom: 0;
			width: 60px;
			cursor: pointer;
			background-color: rgba(40,26,5,0.65);
			background-size: 17px auto;
			background-repeat: no-repeat;
			background-position: center;
		}

		#main-navi-scroll-left
		{
			box-shadow: 10px 0px 30px rgba(0, 0, 0, 0.5), inset 0px 0px 30px rgba(0, 0, 0, 0.5);
			background-image: url('/images/navi/arrow-left.svg');
			border-right: 1px solid $brand-primary;
			left: -60px;
			transition: 0.5s left;

			&.in
			{
				left: 0;
			}
		}

		#main-navi-scroll-right
		{
			right: 0;
			box-shadow: -10px 0px 30px rgba(0, 0, 0, 0.5), inset 0px 0px 30px rgba(0, 0, 0, 0.5);
			background-image: url('/images/navi/arrow-right.svg');
			border-left: 1px solid $brand-primary;
			right: -60px;
			transition: 0.5s right;

			&.in
			{
				right: 0;
			}
		}
	}
}

#swipe-hint
{
	display: none;
}

html.touch
{
	#swipe-hint
	{
		display: block;
		position: absolute;
		right: 10px;
		bottom: 5px;
		color: rgba(255,255,255,0.25);
		@extend .font-hiroshige;
		background-repeat: no-repeat;
		background-size: 7px auto;
		padding-right: 30px;
	}

	#swipe-hint
	{
		background-image: url('/images/navi/touch-left.svg'), url('/images/navi/touch-right.svg');
		background-position: right 15px center, right center;
	}
}

article
{
	color: #fff;

	.icon-container
	{
		text-align: right;

		img
		{
			width: 75px;
		}
	}

	time
	{
		@extend .font-bold;
		color: $brand-primary;
	}

	h1
	{
		margin: 8px 0 5px;
	}

	h2
	{
		margin: 0;
	}

	hr
	{
		margin: 25px auto 30px 0;
		width: 30px;
		border-top: 2px solid $brand-primary;
	}

	p
	{
		line-height: 30px;
	}

	.gallery-image,
	.gallery-show-all
	{
		display: block;
		padding-bottom: 75%;
		background: none no-repeat center;
		background-size: cover;
		margin-bottom: 20px;
	}

	.gallery-show-all
	{
		background-image: url('/images/gallery-show-all.jpg');
		position: relative;

		span
		{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 10px;
			@extend .font-hiroshige;
			text-align: center;
			font-size: 20px;

			&:after
			{
				content: "";
				display: block;
				width: 30px;
				margin: auto;
				border-bottom: 2px solid $brand-primary;
			}
		}

		&:before
		{
			content: "";
			display: block;
			width: 15px;
			height: 15px;
			position: absolute;
			right: 0;
			top: 0;
			border-top: 1px solid $brand-primary;
			border-right: 1px solid $brand-primary;
		}
	}

	ul.hashtags
	{
		list-style-type: none;
		margin: 0 0 15px;
		padding: 0;

		li
		{
			display: inline-block;
			margin-right: 20px;

			a
			{
				font-size: 12px;
				@extend .font-bold;
			}
		}
	}

	+ article
	{
		background: url('/images/malt.svg') no-repeat center 40px;
		background-size: auto 120px;
		padding-top: 225px;

		&:before
		{
			content: "";
			display: block;
			height: 2px;
			background: linear-gradient(to right, rgba($brand-primary,0.10) 10%,rgba($brand-primary,0.80) 25%,rgba($brand-primary,0.80) 75%,rgba($brand-primary,0.10) 100%);
			position: relative;
			top: -67px;
		}
	}
}

footer
{
	height: 198px;
	padding-top: 75px;
	background: url('/images/footer.jpg') no-repeat center;
	background-size: cover;
	position: relative;
	box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.5);
	@extend .font-hiroshige;

	&:before
	{
		content: "";
		display: block;
		height: 2px;
		background: linear-gradient(to right, rgba($brand-primary,0.10) 10%,rgba($brand-primary,0.80) 25%,rgba($brand-primary,0.80) 75%,rgba($brand-primary,0.10) 100%);
		position: absolute;
		left: 0;
		right: 0;
		top: -1px;
	}

	&:after
	{
		content: url('/images/hop.svg');
		display: block;
		width: 83px;
		position: absolute;
		left: 0;
		right: 0;
		top: -1px;
		margin: auto;
	}

	> div
	{
		display: table;
		height: 90px;
		padding: 0 130px;
		width: 100%;
  		text-align: center;
		background: url('/images/icons/logo.svg') no-repeat left center;
		background-size: 92px 90px;
		max-width: 1000px;
		margin: auto;

		> div
		{
			display: table-cell;
			vertical-align: middle;
		}
	}

	.copyright
	{
		color: rgba(255,255,255,0.5);
		display: inline-block;
		margin-right: 15px;
	}

	a
	{
		display: inline-block;
		margin: 0 15px;
		color: #fff;

		&:hover
		{
			color: $brand-primary;
			text-decoration:none;
		}
	}

	@media screen and (max-width: $screen-xs-max) {
		padding-top: 25px;
		height: auto;

		> div
		{
			height: auto;
			padding: 0;
			display: block;
			text-align: left;
			background-position: 15px top;
		}

		.copyright
		{
			padding: 23px 0 0 120px;
		}

		#menu-footer-navi
		{
			white-space: normal!important;
			text-align: center;
			margin-bottom: 0;
			padding: 30px 0 0 0;

			a
			{
				padding: 10px 0;
			}
		}

		&:before,
		&:after
		{
			display: none;
		}
	}
}

#menu-footer-navi
{
	padding: 0;

	.fa
	{
		font-size: 20px;
		position: relative;
		bottom: -2px;
	}
}

#scrollTop
{
	position: fixed;
	right: 40px;
	bottom: 50px;
	padding-top: 25px;
	@extend .font-hiroshige;
	color: #fff;
	text-decoration: none;
	background: url('/images/icons/back_to_top.svg') no-repeat center top;
	background-size: 35px auto;

	@media screen and (max-width: $screen-xs-max) {
		display: none;
	}
}