/* Footer styling */

.menu-footer-navi-container {
	display: inline-block;	
}

#menu-footer-navi {

	li {
		display: inline;
	}

}