.thumbnail-container
{
	text-align: center;
}

.gallery-image-container
{
	margin: 5px;
	display: inline-block;
}

@media screen and (max-width: $screen-xs-max){
	.gallery-thumb
	{
		max-width: 100%;
		height: auto!important;
	}
}