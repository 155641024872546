.ninja-row,
.ninja-forms-cont
{
	// @include make-row();
	margin-left: -15px!important;
    margin-right: -15px!important;
}

.ninja-forms-form-wrap
{
	@include make-sm-column(10);
	@include make-sm-column-offset(1);

	.ninja-col-1-2
	{
		@include make-sm-column(6);
	}

	.ninja-col-2-2
	{
		@include make-sm-column(12);
	}

	.ninja-forms-required-items
	{
		margin-bottom: 35px;
	}

	.field-wrap.label-left,
	.field-wrap.label-above
	{
		margin-bottom: 35px;

		> label
		{
			display: none;
		}

		.ninja-forms-field
		{
			margin: 0;
		}
	}

	.list-dropdown-wrap.label-above
	{
		background-color: rgba(0,0,0,0.3);
		border: 1px solid $brand-primary;
		overflow: hidden;

		select
		{
			background: transparent;
			width: 110%;
			padding: 5px 0 5px 10px;
			appearance: none;
		}
	}

	input
	{
		&[type=text],
		&[type=email],
		&[type=password]
		{
			transition: all 0.25s ease-out;

			background-color: rgba(0,0,0,0.3);
			width: 100%;
			box-shadow: none;
			border: 1px solid $brand-primary;
		}
	}

	textarea {
		transition: all 0.25s ease-out;

		background-color: rgba(0,0,0,0.3);
		width: 100%!important;
		box-shadow: none;
		resize: none;
		border: 1px solid $brand-primary;
	}

	.ninja-forms-error
	{
		position: relative;

		input
		{
			border: none;
			border-bottom: 2px solid $brand-danger;
		}

		.ninja-forms-field-error
		{
			color: $brand-danger;
			margin-top: 5px;
		}

		&:before,
		&:after
		{
			content: "";
			display: block;
			border-left: 2px solid $brand-danger;
			position: absolute;
			top: 28px;
			height: 7px;
		}

		&:after
		{
			right: 0;
		}
	}
}

.btn
{
	min-width: 180px;
	border-width: 2px;
	@extend .font-hiroshige;
	padding-top: 8px;
	padding-bottom: 8px;
}

html.no-touch a.btn.btn-primary
{
	position: relative;

	span.text
	{
		position: relative;
		z-index: 1;
	}

	&:after
	{
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 0;
		z-index: 0;
		background-color: rgba($brand-primary,0.35);
		transition: all 1.5s ease-out;
	}

	&:hover
	{
		border-color: $brand-primary;

		&:after
		{
			height: 100%;
		}
	}
}

html.touch a.btn.btn-primary,
input.btn.btn-primary
{
	background-color: rgba($brand-primary,0.35);
}

.checkbox label,
.radio label,
input,
select,
textarea
{
	@extend .font-bold;
}

select.form-control
{
	option
	{
		color: $gray-base; /* Fix for IE */
	}
}

input
{
	&[type=text],
	&[type=email],
	&[type=password]
	{
		transition: all 0.25s linear;
	}
}

.form-group
{
	margin-bottom: 35px;

	&.input
	{
		position: relative;

		input
		{
			border-width: 2px;
		}

		&:before,
		&:after
		{
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			border-left: 2px solid $brand-secondary;
			height: 6px;
		}

		&:before
		{
			left: 0;
		}

		&:after
		{
			right: 0;
		}
	}
}

input[type=radio]
{
	display: none;

	+ label
	{
		background: url('/images/form/radio.svg') no-repeat left 8px;
		padding-left: 25px;
		background-size: 15px 15px;
	}

	&:checked
	{
		+ label
		{
			background-image: url('/images/form/radio_selected.svg');
		}
	}
}

input[type=checkbox]
{
	display: none!important;

	+ label
	{
		background: url('/images/form/checkbox.svg') no-repeat left 2px;
		padding-left: 25px;
		background-size: 15px 15px;
		line-height: 20px;
	}

	&:checked
	{
		+ label
		{
			background-image: url('/images/form/checkbox_checked.svg');
		}
	}
}

/* Ugly Event Registration Dropdown  Styling */
.em-ticket-select
{
	@extend .form-control;
	width: 100px;
}



.alert-danger {
	background-color: rgba(200,20,20,0.5);
	border-color: #a94442;
	color: white;
	border-radius: 5px;
	padding: 2px 10px;
}

.modal-content input::placeholder {
	color: #666666;
}