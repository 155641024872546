body.mein-konto .woocommerce
{
	h1 + h2
	{
		margin: 0;
		border-bottom: none;

		+ hr
		{
			margin-bottom: 0;
		}
	}

	h2
	{
		margin: 60px 0 40px;
		border-bottom: 1px solid #fff;
	}
}