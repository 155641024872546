/**
 * @license
 * MyFonts Webfont Build ID 3074442, 2015-08-12T08:50:16-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Hiroshige-Med by URW++
 * URL: http://www.myfonts.com/fonts/urw/hiroshige/medium/
 * Copyright: (URW)++,Copyright 2006 by (URW)++ Design &amp; Development
 * Licensed pageviews: 200,000
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3074442
 *
 * © 2015 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/2ee98a");


@font-face {
  font-family: 'Hiroshige-Med';
  src: url('/fonts/2EE98A_0_0.eot');
  src: url('/fonts/2EE98A_0_0.eot?#iefix') format('embedded-opentype'), url('/fonts/2EE98A_0_0.woff2') format('woff2'), url('/fonts/2EE98A_0_0.woff') format('woff'), url('/fonts/2EE98A_0_0.ttf') format('truetype');
}


.font-regular
{
  font-family: Helvetica, Arial;
  font-weight: normal;
}

.font-bold
{
  font-family: Helvetica, Arial;
  font-weight: bold;
}

.font-hiroshige
{
  font-family: 'Hiroshige-Med';
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6, strong, b
{
  @extend .font-hiroshige;
}